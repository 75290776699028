.contacts {
  margin-top: 190px;
  padding-bottom: 228px;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid gray;

  @media (max-width: 1450px) {
    max-width: 900px;

    align-items: center;
  }

  @media (max-width: 810px) {
    width: 100%;

    margin-top: 100px;
    padding-bottom: 120px;

    border-bottom: 1px solid gray;
  }
}

.contacts__map {
  height: 22vw;
  max-width: 1600px;
  padding: 1vw;
  border: 2px solid black;

  @media (max-width: 1450px) {
    max-width: 900px;

    width: 100%;
  }

  @media (max-width: 810px) {
    width: 120%;
    height: 200px;

    border: 1px solid black;
  }

  * {
    margin: 0;
    padding: 0;
  }
}

.contacts__details {
  margin-top: 55px;
  display: flex;
  gap: 120px;
  font-size: 20px;

  @media (max-width: 1450px) {
    max-width: 900px;
    gap: 20px;

    font-size: 14px;
  }

  @media (max-width: 810px) {
    width: 100%;

    display: flex;
    flex-direction: column;
  }

  p.blue {
    padding: 16px;
    border-radius: 12px;
    margin-top: 12px;

    @media (max-width: 810px) {
      width: auto;
      display: flex;
      justify-content: center;
    }
  }

  .contacts__adress {
    width: 475px;

    @media (max-width: 1450px) {
      max-width: 900px;
      width: auto;
    }

    .blue {
      white-space: nowrap;
    }
  }
}

.contacts__logos {
  width: 184px;
  display: flex;
  flex-direction: row;
  gap: 65px;
  position: relative;
  right: -20%;
  top: 40px;
  height: fit-content;

  @media (max-width: 1450px) {
    max-width: 900px;
    right: 0;

    gap: 30px;

    margin-left: 100px;
  }

  @media (max-width: 810px) {
    width: 100%;
    margin: 0;

    display: flex;
    justify-content: center;
  }

  *:hover {
    cursor: pointer;
  }
}

.contacts__logo-vk {
  width: 64px;
  height: 40px;
  background-image: url(../images/contacts-__logo--vk.svg);
  background-size: cover;

  &:hover {
    scale: 1.05;
  }
}

.contacts__logo-tg {
  width: 55px;
  height: 47px;
  background-image: url(../images/contacts__logo--tg.svg);
  background-size: cover;

  &:hover {
    scale: 1.05;
  }
}