.nav {
  width: 85%;
  margin: 0 auto;
  padding: 54px 0;
  display: flex;
  justify-content: space-between;
  transition: all 0.5s ease-in;

  @media (max-width: 1450px) {
    max-width: 900px;
  }

  @media (max-width: 810px) {
    font-size: 14px;
  }
}

.nav__link:hover {
  cursor: pointer;
  font-weight: 500;
}