.about {
  margin-top: 190px;

  @media (max-width: 1450px) {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 810px) {
    margin-top: 100px;
  }
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 55px;

  @media (max-width: 1450px) {
    max-width: 900px;

    grid-template-rows: none;

    gap: 25px;
  }

  @media (max-width: 810px) {
    display: flex;
    flex-direction: column;
  }

  * {
    border-radius: 20px;
    background-color: white;
    padding: 45px 75px;
    max-height: 101px;
    font-size: 18px;
  }
}

.card-one {
  grid-row: 1/2;
  grid-column: 1/4;
  display: grid;
  grid-template-rows: 40px 40px;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 40px;
  column-gap: 156px;
  padding: 40px 75px 60px;

  @media (max-width: 1450px) {
    max-width: 900px;
    max-height: 100%;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 810px) {
    width: auto;

    display: flex;
    flex-direction: column;

    padding: 30px 30px;
  }

  * {
    padding: 0;
  }
}

.about__fact {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-rows: 40px;
  gap: 15px;

  P {
    display: flex;
    align-items: center;
  }
}

.about__fact:nth-of-type(1) .about__icon {
  background-image: url(../images/about-1.svg);
}

.about__fact:nth-of-type(2) .about__icon {
  background-image: url(../images/about-2.svg);
}

.about__fact:nth-of-type(3) .about__icon {
  background-image: url(../images/about-3.svg);
}

.about__fact:nth-of-type(4) .about__icon {
  background-image: url(../images/about-4.svg);
}

.about__fact:nth-of-type(5) .about__icon {
  background-image: url(../images/about-5.svg);
}

.about__fact:nth-of-type(6) .about__icon {
  background-image: url(../images/about-6.svg);
}

.about__text {
  max-width: 300px;
}

.about__fact {
  @media (max-width: 1638px) {
    p {
      font-size: 12px;
    }
  }
}

.card-two {
  max-width: 380px;
  padding: 45px 75px;
  height: fit-content;

  @media (min-width: 1448px) and (max-width: 1880px) {
    font-size: 14px;
    padding: 35px 55px;

    b {
      font-size: 16px;
    }
  }

  @media (max-width: 1450px) {
    max-width: 100%;
    font-size: 14px;

    padding: 30px 35px;

    b {
      font-size: 14px;
    }
  }
}

.card-three {
  grid-row: 2/3;
  grid-column: 2/4;

  @media (max-width: 1450px) {
    max-width: 900px;
  }

  @media (max-width: 810px) {
    font-size: 18px !important;
    display: none !important;
}
}

.card-four {
  grid-row: 3/4;
  grid-column: 1/3;

  @media (max-width: 1450px) {
    max-width: 900px;
  }

  @media (max-width: 810px) {
    font-size: 18px !important;
    display: none !important;
}
}

.card-five {
  max-width: 382px;

  @media (min-width: 1448px) and (max-width: 1880px) {
    font-size: 14px;
    padding: 35px 55px;

    b {
      font-size: 16px;
    }
  }

  @media (max-width: 1450px) {
    max-width: 900px;
    font-size: 14px;

    padding: 30px 35px;

    b {
      font-size: 14px;
    }
  }
}

.card-six {
  max-width: 382px;

  @media (min-width: 1448px) and (max-width: 1880px) {
    font-size: 14px;
    padding: 35px 55px;

    b {
      font-size: 16px;
    }
  }

  @media (max-width: 1450px) {
    max-width: 900px;
    font-size: 14px;

    padding: 30px 35px;

    b {
      font-size: 14px;
    }
  }
}

.card-seven {
  grid-row: 4/5;
  grid-column: 2/4;

  @media (max-width: 1450px) {
    max-width: 900px;
  }

  @media (max-width: 810px) {
    font-size: 18px !important;
    display: none !important;
}
}