.intro {
  height: fit-content;
  max-height: 580px;
  padding: 80px 0 120px;
  background-image: url(../images/mycourses.svg);
  background-repeat: no-repeat;
  background-position: 50% 57%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 69px;

  @media (max-width: 1450px) {
    max-width: 100%;
    width: 900px;
  }

  @media (max-width: 810px) {
    min-width: 125%;

    background-image: none;
    border-radius: 0px;

    padding: 50px 0 50px;
  }
}

/*intro*/
.intro__logo {
  width: 170px;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../images/logo.svg);
}

.intro__heading {
  margin: 80px 0 150px;
  font-family: "League Spartan";
  font-size: 137px;
  font-weight: 700;
  line-height: 126.04px;
  text-align: left;

  @media (max-width: 810px) {
    font-size: 50px;
    margin: 20px 0 20px;
  }
}

.intro__button {
  width: 250px;
  padding: 22px 0;

  font-size: 16px;

  border: 2px solid;
  background: none;
  border-radius: 12px;
  transition: all 0.2s linear;
}

.intro__button:hover {
  cursor: pointer;

  background-color: #0d3768;
}