.footer {
  width: 70%;
  height: 200px;
  margin: 0 auto;

  @media (max-width: 810px) {
    width: 100%;
    font-size: 12px;
  }
}

.footer__credits {
  color: #606060;
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1450px) {
    max-width: 900px;
    font-size: 12px;

    gap: 40px;
  }

  @media (max-width: 810px) {
    width: 100%;
  }
}