.catalogue {
  margin-top: 190px;

  @media (max-width: 1450px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 810px) {
    margin-top: 100px;
}
}


.catalogue__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 67px;

  @media (max-width: 1450px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 900px;
  }
}

.catalogue__card {
  max-width: 100%;
  height: 259px;
  display: grid;
  grid-template-columns: 102px 1fr;
  grid-template-rows: 54px 48px 1fr;
  border-radius: 20px;
  overflow: hidden;
  background-color: white;

  transition: 0.3s ease-in-out;

  @media (max-width: 1450px) {
    max-width: 70%;
  }

  @media (max-width: 810px) {
    max-width: 100%;
    height: 200px;
}
}

.catalogue__card:hover,
.catalogue__card--coursepage {
  width: 100%;
  background-color: #11447f;
  transition: 0.3s ease-in-out;
  color: white !important;


  @media (max-width: 1450px) {
    max-width: 70%;
  }

  @media (max-width: 810px) {
    max-width: 100%;
}

  .card__duration {
    color: white;
  }
}

.card__logo {
  grid-row: 1/3;
  grid-column: 1/2;
  background-color: #11447f;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 20px 0px 20px 20px;
}

.card__heading {
  display: flex;
  align-items: center;

  font-size: 24px;
  padding: 5px 55px 0 55px;
  position: relative;

  @media (min-width: 1448px) and (max-width: 1880px) {
    font-size: 16px;
    padding: 5px 35px 0 35px;
  }

  @media (max-width: 810px) {
    font-size: 16px;
    padding: 5px 15px;
}
}

.card__duration {
  padding: 14px 0 0 35px;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #11447f;

  @media (max-width: 810px) {
    font-size: 14px;
    padding: 10px 0 0 15px;
  }
}

.card__description,
.card__salary {
  padding: 30px 25px;
  max-width: 100%;
  grid-row: 3/4;
  grid-column: 1/3;

  @media (max-width: 810px) {
    padding: 0px 10px 0px 10px;
}
}

.card__description {
  @media (max-width: 810px) {
    font-size: 12px !important;
    padding: 20px 20px;
}
}

.card__buttons {
  width: 100%;
  grid-column: 1/3;
  display: flex;
  justify-content: center;
  gap: 33px;
  padding-bottom: 21px;

  transition: all 0.2s linear;

  @media (max-width: 810px) {
    padding-bottom: 15px;

    gap: 10px;
  }

  button {
    @media (max-width: 810px) {
      width: 115px;
      height: 50px;

      font-size: 14px;
  }
  }

  * {
    background: none;
    border: 2px solid white;
    color: white;
    border-radius: 12px;
    font-size: 18px;
    width: 194px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button:hover {
    background-color: #0d3768;
    cursor: pointer;
  }
}

.coursePrice {
  color: white;
  background-color: none;

  font-size: 18px;
  font-weight: 600;

  @media (max-width: 810px) {
    font-size: 14px;
    padding: 0px 20px;
}
}