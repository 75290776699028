.courseMainBox {
  max-width: 1400px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  justify-content: center;

  @media (max-width: 1450px) {
    max-width: 900px;
  .mainTitle {
    display: none;
    justify-content: center;
  }
}
}

.course {
    height: 100vh;
  }
  .course__body {
    display: grid;
    justify-content: center;

    grid-template-columns: 463px 898px;
    grid-template-rows: 259px 330px;
    gap: 40px;

    @media (max-width: 1450px) {
      max-width: 900px;

      grid-template-columns: none;
      justify-items: center;

      margin-bottom: 100px;
    }
  }
  .course__image {
    background-size: cover;

    border-radius: 20px;

    @media (max-width: 1450px) {
      max-width: 900px;
      display: none;
    }
  }
  
  
  .course__full-description,
  .course__details {
    padding: 40px;
    font-size: 18px;
    background-color: white;
    border-radius: 20px;
    line-height: 22px;

    @media (max-width: 1450px) {
      max-width: 60%;
      height: auto;

      display: flex;
      flex-direction: column;
    }
  }

  .course__full-description {
    @media (max-width: 1450px) {
      max-width: 60%;

      display: flex;
      flex-direction: column;
    }
  }

  .course__details {
    @media (max-width: 1450px) {
      padding: 40px;
      font-size: 18px;
      background-color: rgb(255, 255, 255);

      border-radius: 20px;
      line-height: 22px;  
    }
  }

  .detailBtn {
    button {
      width: 420px;
    }
  }

  .btnHide {
    button {
      @media (max-width: 810px) {
        display: none !important;
      }
    }
  }
  